body {
  background: #f5f5f5;
}

.bg-body {
  background: #f5f5f5 !important;
}

.text-primary {
  color: #26B4FF !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #008ed9 !important;
}

.bg-primary {
  background-color: #26B4FF !important;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #24abf2 !important;
}

.bg-primary-dark {
  background-color: #22a2e6 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus {
  background-color: #209adb !important;
}

.bg-primary-darker {
  background-color: #2099d9 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus {
  background-color: #1e91ce !important;
}

html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
  border-color: #26B4FF !important;
}

.badge-primary {
  background-color: #26B4FF;
  color: #fff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #24abf2;
  color: #fff;
  text-decoration: none;
}

.btn .badge-primary {
  background: #26B4FF !important;
  border-color: #26B4FF !important;
  color: #fff !important;
}

.badge-outline-primary {
  background-color: transparent;
  box-shadow: 0 0 0 1px #26B4FF inset;
  color: #26B4FF;
}

.badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #26B4FF;
  text-decoration: none;
}

.btn .badge-outline-primary {
  background-color: transparent !important;
  box-shadow: 0 0 0 1px #26B4FF inset !important;
  color: #26B4FF !important;
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #26B4FF;
  background-color: #26B4FF;
  color: #fff;
}

.progress-bar {
  background-color: #26B4FF;
  color: #fff;
}

.list-group-item-primary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #e9f8ff;
  color: #5d6366;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #5d6366;
}

a.list-group-item-primary:hover, a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
  background-color: #e7f6fc;
  color: #5d6366;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  border-color: #26B4FF;
  background-color: #26B4FF;
  color: #fff;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #26B4FF;
  background-color: #26B4FF;
  color: #fff;
}

.alert-primary {
  border-color: #d1e5f0;
  background-color: #def4ff;
  color: #596266;
}

.alert-primary hr {
  border-top-color: #d1e5f0;
}

.alert-primary .alert-link {
  color: #596266;
}

.alert-dark-primary {
  background-color: #26B4FF;
  color: #fff;
}

.alert-dark-primary hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.alert-dark-primary .close,
.alert-dark-primary .alert-link {
  color: #fff;
}

.callout-primary {
  border-color: #26B4FF !important;
  background-color: #def4ff;
  color: #596266;
}

.callout-primary .callout-link {
  color: #596266;
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #26B4FF;
  color: #fff;
}

.tooltip-primary.bs-tooltip-top .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #26B4FF;
}

.tooltip-primary.bs-tooltip-right .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #26B4FF;
}

.tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #26B4FF;
}

.tooltip-primary.bs-tooltip-left .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #26B4FF;
}

.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #26B4FF;
}

.popover-primary .popover-header, .popover-primary > .popover .popover-header, .ngb-popover-primary + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}

.popover-primary .popover-body, .popover-primary > .popover .popover-body, .ngb-popover-primary + ngb-popover-window .popover-body {
  background: transparent;
  color: #fff;
}

.popover-primary > .arrow::before, .popover-primary > .popover > .arrow::before, .ngb-popover-primary + ngb-popover-window > .arrow::before {
  border-color: transparent;
}

.popover-primary.bs-popover-top > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] > .arrow::after, .popover-primary > .popover.bs-popover-top > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="top"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-top > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: #26B4FF;
}

.popover-primary.bs-popover-right > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] > .arrow::after, .popover-primary > .popover.bs-popover-right > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="right"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-right > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="right"] > .arrow::after {
  border-right-color: #26B4FF;
}

.popover-primary.bs-popover-bottom > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .popover-primary > .popover.bs-popover-bottom > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="bottom"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-bottom > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: #26B4FF;
}

.popover-primary.bs-popover-left > .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] > .arrow::after, .popover-primary > .popover.bs-popover-left > .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="left"] > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-left > .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="left"] > .arrow::after {
  border-left-color: #26B4FF;
}

.table .table-primary,
.table .table-primary > th,
.table .table-primary > td {
  border-color: rgba(0, 0, 0, 0.035) !important;
  background-color: #e9f8ff;
  color: #5d6366;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e7f6fc;
}

.btn-primary {
  border-color: transparent;
  background: #26B4FF;
  color: #fff;
}

.btn-primary:hover {
  border-color: transparent;
  background: #24abf2;
  color: #fff;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4);
}

.btn-primary.disabled, .btn-primary:disabled {
  border-color: transparent !important;
  background: #26B4FF !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-primary .badge {
  background: #fff;
  color: #26B4FF;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  border-color: transparent;
  background: #219dde;
  box-shadow: none;
}

.btn-group .btn-primary,
.input-group-prepend .btn-primary,
.input-group-append .btn-primary {
  border-right: 1px solid #219dde;
  border-left: 1px solid #219dde;
}

.btn-outline-primary {
  border-color: #26B4FF;
  background: transparent;
  color: #26B4FF;
}

.btn-outline-primary:hover {
  border-color: transparent;
  background: #26B4FF;
  color: #fff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  border-color: #26B4FF !important;
  background: transparent !important;
  color: #26B4FF !important;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  border-color: transparent;
  background: #24abf2;
  color: #fff;
  box-shadow: none;
}

.btn-outline-primary .badge {
  background: #26B4FF;
  border-color: #26B4FF;
  color: #fff;
}

.btn-outline-primary:hover .badge,
.btn-outline-primary:focus .badge,
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show > .btn-outline-primary.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #26B4FF;
}

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background-color: #26B4FF;
  color: #fff;
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active,
.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: #26B4FF;
  color: #fff;
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #26B4FF;
  color: #fff;
}

.tabs-alt.nav-tabs .nav-link.active, .tabs-alt.nav-tabs .nav-link.active:hover, .tabs-alt.nav-tabs .nav-link.active:focus,
.tabs-alt > .nav-tabs .nav-link.active,
.tabs-alt > .nav-tabs .nav-link.active:hover,
.tabs-alt > .nav-tabs .nav-link.active:focus {
  box-shadow: 0 -2px 0 #26B4FF inset;
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before,
.custom-control .custom-control-input:active ~ .custom-control-label::before {
  border-color: #26B4FF;
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4);
}

.custom-control.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before,
.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #26B4FF;
  background-color: #26B4FF;
}

.custom-control.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #26B4FF;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: inherit;
}

.form-control:focus,
.form-control.focus,
.custom-select:focus {
  border-color: #26B4FF;
}

.switcher-input:checked ~ .switcher-indicator {
  background: #26B4FF;
  color: #fff;
}

.switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4);
}

.switcher-input:active ~ .switcher-indicator {
  box-shadow: none;
}

.navbar.bg-primary {
  background-color: #26B4FF !important;
  color: #daf2ff;
}

.navbar.bg-primary .navbar-brand {
  color: #fff;
}

.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-primary .navbar-nav .nav-link {
  color: #daf2ff;
}

.navbar.bg-primary .navbar-nav .nav-link:hover, .navbar.bg-primary .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-primary .navbar-nav .nav-link.disabled {
  color: #92d9ff !important;
}

.navbar.bg-primary .navbar-nav .show > .nav-link,
.navbar.bg-primary .navbar-nav .active > .nav-link,
.navbar.bg-primary .navbar-nav .nav-link.show,
.navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-primary .navbar-toggler {
  color: #daf2ff;
  border-color: rgba(255, 255, 255, 0.15);
}

.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar.bg-primary .navbar-text {
  color: #daf2ff;
}

.navbar.bg-primary .navbar-text a {
  color: #fff;
}

.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-primary {
  background-color: #26B4FF !important;
  color: #daf2ff;
}

.sidenav.bg-primary .sidenav-link,
.sidenav.bg-primary .sidenav-horizontal-prev,
.sidenav.bg-primary .sidenav-horizontal-next {
  color: #daf2ff;
}

.sidenav.bg-primary .sidenav-link:hover, .sidenav.bg-primary .sidenav-link:focus,
.sidenav.bg-primary .sidenav-horizontal-prev:hover,
.sidenav.bg-primary .sidenav-horizontal-prev:focus,
.sidenav.bg-primary .sidenav-horizontal-next:hover,
.sidenav.bg-primary .sidenav-horizontal-next:focus {
  color: #fff;
}

.sidenav.bg-primary .sidenav-link.active,
.sidenav.bg-primary .sidenav-horizontal-prev.active,
.sidenav.bg-primary .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-primary .sidenav-item.disabled .sidenav-link,
.sidenav.bg-primary .sidenav-horizontal-prev.disabled,
.sidenav.bg-primary .sidenav-horizontal-next.disabled {
  color: #92d9ff !important;
}

.sidenav.bg-primary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.sidenav.bg-primary .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-primary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #eeeeee;
  border-radius: 5px;
  font-weight: bold;
}

.sidenav.bg-primary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #eeeeee;
  border-radius: 5px solid;
  font-weight: bold;
}

.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #24abf2;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #daf2ff;
}

.sidenav.bg-primary .sidenav-text {
  color: #fff;
}

.sidenav.bg-primary .sidenav-header {
  color: #ade3ff;
}

.sidenav.bg-primary hr,
.sidenav.bg-primary .sidenav-divider,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-header::before,
.sidenav.bg-primary .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #45b8f4;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #24abf2;
}

.sidenav.bg-primary .ps__thumb-y,
.sidenav.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.629164) !important;
}

.footer.bg-primary {
  background-color: #26B4FF !important;
  color: #daf2ff;
}

.footer.bg-primary .footer-link {
  color: #daf2ff;
}

.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #fff;
}

.footer.bg-primary .footer-link.disabled {
  color: #92d9ff !important;
}

.footer.bg-primary .footer-text {
  color: #fff;
}

.footer.bg-primary .show > .footer-link,
.footer.bg-primary .active > .footer-link,
.footer.bg-primary .footer-link.show,
.footer.bg-primary .footer-link.active {
  color: #fff;
}

.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}

.bg-primary.bs4-toast {
  color: #fff;
  background-color: rgba(38, 180, 255, 0.9) !important;
}

.bg-primary.bs4-toast .toast-header {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.fill-primary {
  fill: #26B4FF;
}

.noUi-primary .noUi-base .noUi-connect {
  background: #26B4FF !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #26B4FF !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #26B4FF !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #26B4FF !important;
}

.ql-snow.ql-container .ql-editor:focus {
  border-color: #26B4FF !important;
}

.dropzone.dz-drag-hover {
  border-color: #26B4FF !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #26B4FF !important;
}

.sk-primary.sk-plane,
.sk-primary .sk-chase-dot:before,
.sk-primary .sk-bounce-dot,
.sk-primary .sk-wave-rect,
.sk-primary.sk-pulse,
.sk-primary .sk-swing-dot,
.sk-primary .sk-circle-dot:before,
.sk-primary .sk-circle-fade-dot:before,
.sk-primary .sk-grid-cube,
.sk-primary .sk-fold-cube:before {
  background-color: #26B4FF;
}

.plyr[class] .plyr__control--overlaid {
  background: rgba(38, 180, 255, 0.75) !important;
  color: #fff !important;
}

.plyr[class] .plyr__control.plyr__tab-focus,
.plyr[class] .plyr__control:hover,
.plyr[class] .plyr__control[aria-expanded='true'] {
  background: #26B4FF !important;
  color: #fff !important;
}

.plyr[class] .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']:not(.plyr__tab-focus):not(:hover)::before {
  background: #26B4FF !important;
}

.plyr[class] .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']:not(.plyr__tab-focus):not(:hover)::after {
  background: #fff !important;
}

.plyr[class] .plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4) !important;
}

.plyr--full-ui[class] input[type='range'] {
  color: #26B4FF !important;
}

.plyr--full-ui[class] input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4) !important;
}

.plyr--full-ui[class] input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4) !important;
}

.plyr--full-ui[class] input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 2px rgba(38, 180, 255, 0.4) !important;
}

.ng-select-focused .ng-select-container {
  border-color: #26B4FF !important;
}

.ng-dropdown-panel .ng-option-marked {
  background: #26B4FF !important;
  color: #fff !important;
}

.ng-select .ng-spinner-loader {
  border-left-color: #26B4FF !important;
}

.ng-select-primary.ng-select-multiple .ng-value,
.ng-select-primary .ng-select-multiple .ng-value {
  background: #26B4FF !important;
  color: #fff !important;
}

.ng2-tag-input--focused {
  border-color: #26B4FF !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag {
  background-color: #26B4FF !important;
  color: #fff !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag:focus, .ngx-chips-primary[class] .ng2-tag-input tag:active {
  background-color: #0098e8 !important;
  color: #fff !important;
}

.ngx-chips-primary[class] .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.ngx-chips-primary[class] .ng2-tag-input.ng2-tag-input--disabled tag,
.ngx-chips-primary[class] .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.ngx-chips-primary[class] .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #26B4FF !important;
  color: #fff !important;
}

.ngx-datatable .datatable-pager ul li:not(.disabled).active a {
  border-color: #0dabff !important;
  background: #26B4FF !important;
  color: #fff !important;
}

.ngx-datatable .progress-linear .container {
  background-color: #26B4FF !important;
}

.ngx-datatable:not(.cell-selection) .datatable-body-row.active,
.ngx-datatable:not(.cell-selection) .datatable-body-row.active .datatable-body-cell,
.ngx-datatable.cell-selection .datatable-body-row .datatable-body-cell.active {
  background-color: #26B4FF !important;
  border-color: rgba(24, 28, 33, 0.05) !important;
}

.ngx-datatable:not(.cell-selection) .datatable-body-row.active,
.ngx-datatable:not(.cell-selection) .datatable-body-row.active .text-dark,
.ngx-datatable:not(.cell-selection) .datatable-body-row.active .datatable-body-cell,
.ngx-datatable:not(.cell-selection) .datatable-body-row.active .datatable-body-cell .text-dark,
.ngx-datatable.cell-selection .datatable-body-row .datatable-body-cell.active,
.ngx-datatable.cell-selection .datatable-body-row .datatable-body-cell.active .text-dark {
  color: #fff !important;
}

wizard ul.steps-indicator li.current::before,
wizard ul.steps-indicator li.editing::before {
  border-color: #26B4FF !important;
  color: #26B4FF !important;
}

.cal-event {
  background: #26B4FF !important;
}

.cal-week-view .cal-event,
.cal-day-view .cal-event {
  background: rgba(38, 180, 255, 0.15) !important;
}

.cal-month-view .cal-day-badge {
  background: #26B4FF !important;
  color: #fff !important;
}

.cal-month-view .cal-day-cell.cal-today,
.cal-week-view .cal-header.cal-today {
  background: rgba(38, 180, 255, 0.05) !important;
}

.cal-month-view .cal-cell.cal-has-events.cal-open {
  background: rgba(38, 180, 255, 0.15) !important;
}

.cal-day-view .cal-all-day-event {
  background: rgba(38, 180, 255, 0.15) !important;
  border-color: rgba(38, 180, 255, 0.15) !important;
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step,
.swal2-progress-steps[class] .swal2-progress-step-line,
.swal2-progress-steps[class] .swal2-active-progress-step,
.swal2-progress-steps[class] .swal2-progress-step {
  background: #26B4FF;
  color: #fff;
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step,
.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #c9ecff;
}

.ui-product-color.active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #26B4FF !important;
}

.bg-navbar-theme {
  background-color: #fff !important;
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-brand {
  color: #4E5155;
}

.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus {
  color: #4E5155;
}

.bg-navbar-theme .navbar-nav .nav-link {
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-nav .nav-link:hover, .bg-navbar-theme .navbar-nav .nav-link:focus {
  color: #4E5155;
}

.bg-navbar-theme .navbar-nav .nav-link.disabled {
  color: #c8c8ca !important;
}

.bg-navbar-theme .navbar-nav .show > .nav-link,
.bg-navbar-theme .navbar-nav .active > .nav-link,
.bg-navbar-theme .navbar-nav .nav-link.show,
.bg-navbar-theme .navbar-nav .nav-link.active {
  color: #4E5155;
}

.bg-navbar-theme .navbar-toggler {
  color: #a3a4a6;
  border-color: rgba(78, 81, 85, 0.075);
}

.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(24, 28, 33, 0.4)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.bg-navbar-theme .navbar-text {
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-text a {
  color: #4E5155;
}

.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #4E5155;
}

.bg-navbar-theme hr {
  border-color: rgba(78, 81, 85, 0.075);
}

.layout-navbar {
  box-shadow: 0 1px 0 rgba(24, 28, 33, 0.06);
}

.bg-sidenav-theme {
  background-color: #2e323a !important;
  color: #838790;
}

.bg-sidenav-theme .sidenav-link,
.bg-sidenav-theme .sidenav-horizontal-prev,
.bg-sidenav-theme .sidenav-horizontal-next {
  color: #838790;
}

.bg-sidenav-theme .sidenav-link:hover, .bg-sidenav-theme .sidenav-link:focus,
.bg-sidenav-theme .sidenav-horizontal-prev:hover,
.bg-sidenav-theme .sidenav-horizontal-prev:focus,
.bg-sidenav-theme .sidenav-horizontal-next:hover,
.bg-sidenav-theme .sidenav-horizontal-next:focus {
  color: #fff;
}

.bg-sidenav-theme .sidenav-link.active,
.bg-sidenav-theme .sidenav-horizontal-prev.active,
.bg-sidenav-theme .sidenav-horizontal-next.active {
  color: #fff;
}

.bg-sidenav-theme .sidenav-item.disabled .sidenav-link,
.bg-sidenav-theme .sidenav-horizontal-prev.disabled,
.bg-sidenav-theme .sidenav-horizontal-next.disabled {
  color: #61656e !important;
}

.bg-sidenav-theme .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
.bg-sidenav-theme .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #eeeeee;
  border-radius: 5px;
  font-weight: bold;
}

.bg-sidenav-theme.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #eeeeee;
  border-radius: 5px solid;
  font-weight: bold;
}

.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #383c44;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  background: transparent;
  color: #838790;
}

.bg-sidenav-theme .sidenav-text {
  color: #fff;
}

.bg-sidenav-theme .sidenav-header {
  color: #6e727b;
}

.bg-sidenav-theme hr,
.bg-sidenav-theme .sidenav-divider,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
  border-color: rgba(255, 255, 255, 0.06) !important;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-header::before,
.bg-sidenav-theme .sidenav-block::before {
  background-color: rgba(255, 255, 255, 0.06);
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
  background-color: #44484f;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  background-color: #fff;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
  box-shadow: 0 0 0 2px #383c44;
}

.bg-sidenav-theme .ps__thumb-y,
.bg-sidenav-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.477986) !important;
}

.bg-footer-theme {
  background-color: #fff !important;
  color: #a3a4a6;
}

.bg-footer-theme .footer-link {
  color: #a3a4a6;
}

.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #4E5155;
}

.bg-footer-theme .footer-link.disabled {
  color: #c8c8ca !important;
}

.bg-footer-theme .footer-text {
  color: #4E5155;
}

.bg-footer-theme .show > .footer-link,
.bg-footer-theme .active > .footer-link,
.bg-footer-theme .footer-link.show,
.bg-footer-theme .footer-link.active {
  color: #4E5155;
}

.bg-footer-theme hr {
  border-color: rgba(78, 81, 85, 0.075);
}
